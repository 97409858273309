export default {
  staffOptions(state) {
    let options = [{ name: 'Any User', _id: '' }]

    let staffOptions = state.dashboard.staff.options.map((staff) => {
      if (staff._id == state.auth.me._id) return { name: 'Me', _id: staff._id }
      return staff
    })

    return [...options, ...staffOptions]
  },

  roleOptions(state) {
    let options = [{ name: 'Choose Role', _id: null, disabled: false }]

    return [...options, ...state.dashboard.roles.options]
  },

  labsOptions(state) {
    let options = [{ name: 'Choose Lab', _id: '', disabled: true }]

    return [...options, ...state.dashboard.labs.options]
  },

  /* For Edit Staff */
  oldStaff(state) {
    let staff = state.dashboard.staff.one

    let data = {
      _id: staff._id,
      username: staff.username,
      fullname: staff.fullname,
      mobile: staff.mobile,
      email: staff.email,
      permissions: staff.permissions,
    }

    if (typeof staff.role === 'object' && staff.role !== null && staff.role._id) {
      data.role = staff.role._id
      if (staff.role.permissions && Array.isArray(staff.role.permissions)) {
        data.permissions = [...data.permissions, ...staff.role.permissions]
      }
    } else {
      data.role = staff.role
    }

    data.picture = (staff.picture && `${process.env.BASE_URL}/images/users/${staff.picture}`) || ''

    return data
  },

  /* For Edit Lab */
  oldLab(state) {
    let lab = state.dashboard.labs.one

    let data = { _id: lab._id, pricePerHour: lab.pricePerHour, deductPoints: lab.deductPoints || 0, flags: lab.flags }

    data.flags.forEach((flag) => {
      flag.hint = []

      for (let translation of lab.translations) {
        let flagHints = translation.hints.filter((hint) => flag._id === hint.flag)

        if (flagHints.length) {
          flagHints.forEach((h) => flag.hint.push({ lang: translation.lang, text: h.text }))
        } else {
          flag.hint.push({ lang: translation.lang, text: '' })
        }
      }
    })

    data.translations = lab.translations.map((translation) => {
      let _translation = {
        _id: translation._id,
        lang: translation.lang,
        title: translation.title,
        description: translation.description,
        image: translation.image,
        imageReview: translation.imageReview,
        demonstration: translation.demonstration,
        agreement: translation.agreement,
        agreementReview: translation.agreementReview,
        skills: translation.skills || [],
        tips: translation.tips || [],
        scopes: translation.scopes || [],
      }

      _translation.skills.push('')

      _translation.tips.push('')

      _translation.scopes.push('')

      return _translation
    })

    return data
  },

  /* For Edit HomePage Page */
  oldHomePage(state) {
    let home = state.dashboard.pages.home

    if (!home || Object.keys(home).length === 0) {
      return {
        labs: ['', '', ''],
        courses: ['', '', '', ''],
        translations: [
          {
            lang: 'en',
            howToUse: {
              demonstration: '',
              description: '',
              show: true,
            },
            realLabs: {
              description: '',
              show: true,
            },
            communityFeedback: {
              description: '',
              show: true,
            },
            faq: {
              description: '',
              show: true,
            },
            featuredCourses: {
              description: '',
              show: true,
            },
          },
          {
            lang: 'ar',
            howToUse: {
              demonstration: '',
              description: '',
              show: true,
            },
            realLabs: {
              description: '',
              show: true,
            },
            communityFeedback: {
              description: '',
              show: true,
            },
            faq: {
              description: '',
              show: true,
            },
            featuredCourses: {
              description: '',
              show: true,
            },
          },
        ],
      }
    }

    let data = { _id: home._id, labs: home.labs, courses: home.courses }

    data.translations = home.translations.map((translation) => {
      let _translation = {
        _id: translation._id,
        lang: translation.lang,
        howToUse: translation.howToUse,
        realLabs: translation.realLabs,
        communityFeedback: translation.communityFeedback,
        faq: translation.faq,
        featuredCourses: translation.featuredCourses,
      }

      return _translation
    })

    return data
  },

  /* For Edit Enterprise Page */
  oldEnterprise(state) {
    let enterprise = state.dashboard.pages.enterprise

    if (!enterprise || Object.keys(enterprise).length === 0) {
      return {
        image: null,
        translations: [
          {
            lang: 'en',
            title: '',
            description: '',
          },
          {
            lang: 'ar',
            title: '',
            description: '',
          },
        ],
      }
    }

    let data = { _id: enterprise._id, image: enterprise.image, imageReview: enterprise.imageReview }

    data.translations = enterprise.translations.map((translation) => {
      let _translation = {
        _id: translation._id,
        lang: translation.lang,
        title: translation.title,
        description: translation.description,
      }

      return _translation
    })

    return data
  },

  /* For Edit Contact Page */
  oldContact(state) {
    let contact = state.dashboard.pages.contact

    if (!contact || Object.keys(contact).length === 0) {
      return {
        email: '',
        phone: '',
        translations: [
          {
            lang: 'en',
            title: '',
            description: '',
            detailsText: '',
            address: '',
          },
          {
            lang: 'ar',
            title: '',
            description: '',
            detailsText: '',
            address: '',
          },
        ],
      }
    }

    let data = { _id: contact._id, email: contact.email, phone: contact.phone }

    data.translations = contact.translations.map((translation) => {
      let _translation = {
        _id: translation._id,
        lang: translation.lang,
        title: translation.title,
        description: translation.description,
        detailsText: translation.detailsText,
        address: translation.address,
      }

      return _translation
    })

    return data
  },

  /* For Edit OpenVpn Page */
  oldOpenVpn(state) {
    let openVpn = state.dashboard.pages.openVpn

    if (!openVpn || Object.keys(openVpn).length === 0) {
      return {
        serverName: '',
        serverStatus: true,
        serverConnected: true,
        internalVirtualIpAddress: '0.0.0.0',
        translations: [
          {
            lang: 'en',
            description: '',
            demonstration: '',
          },
          {
            lang: 'ar',
            description: '',
            demonstration: '',
          },
        ],
      }
    }

    let data = {
      _id: openVpn._id,
      serverName: openVpn.serverName,
      serverStatus: openVpn.serverStatus,
      serverConnected: openVpn.serverConnected,
      internalVirtualIpAddress: openVpn.internalVirtualIpAddress,
    }

    data.translations = openVpn.translations.map((translation) => {
      let _translation = {
        _id: translation._id,
        lang: translation.lang,
        description: translation.description,
        demonstration: translation.demonstration,
      }

      return _translation
    })

    return data
  },

  /* For Edit Terms Page */
  oldTerms(state) {
    let terms = state.dashboard.pages.terms

    if (!terms || Object.keys(terms).length === 0) {
      return {
        translations: [
          {
            lang: 'en',
            text: '',
          },
          {
            lang: 'ar',
            text: '',
          },
        ],
      }
    }

    let data = { _id: terms._id }

    data.translations = terms.translations.map((translation) => {
      let _translation = {
        _id: translation._id,
        lang: translation.lang,
        text: translation.text,
      }

      return _translation
    })

    return data
  },

  /* For Edit Privacy Page */
  oldPrivacy(state) {
    let privacy = state.dashboard.pages.privacy

    if (!privacy || Object.keys(privacy).length === 0) {
      return {
        translations: [
          {
            lang: 'en',
            text: '',
          },
          {
            lang: 'ar',
            text: '',
          },
        ],
      }
    }

    let data = { _id: privacy._id }

    data.translations = privacy.translations.map((translation) => {
      let _translation = {
        _id: translation._id,
        lang: translation.lang,
        text: translation.text,
      }

      return _translation
    })

    return data
  },

  /* For Edit Footer Page */
  oldFooter(state) {
    let footer = state.dashboard.pages.footer

    if (!footer || Object.keys(footer).length === 0) {
      return {
        links: {
          facebook: '',
          twitter: '',
          linkedin: '',
          youtube: '',
          instagram: '',
        },
        translations: [
          {
            lang: 'en',
            description: '',
          },
          {
            lang: 'ar',
            description: '',
          },
        ],
      }
    }

    let data = { _id: footer._id, links: footer.links }

    data.translations = footer.translations.map((translation) => {
      let _translation = {
        _id: translation._id,
        lang: translation.lang,
        description: translation.description,
      }

      return _translation
    })

    return data
  },

  /* For Edit Slide */
  oldSlide(state) {
    let slide = state.dashboard.pages.slides.one

    if (!slide._id) return {}

    let data = { _id: slide._id, image: slide.image, imageReview: slide.imageReview }

    data.translations = slide.translations.map((translation) => {
      let _translation = {
        _id: translation._id,
        lang: translation.lang,
        title: translation.title,
        description: translation.description,
      }

      return _translation
    })

    return data
  },

  /* For Edit Section */
  oldSection(state) {
    let section = state.dashboard.pages.sections.one

    if (!section._id) return {}

    let data = { _id: section._id, image: section.image, imageReview: section.imageReview }

    data.translations = section.translations.map((translation) => {
      let _translation = {
        _id: translation._id,
        lang: translation.lang,
        title: translation.title,
        description: translation.description,
      }

      return _translation
    })

    return data
  },

  /* For Edit Feedback */
  oldFeedback(state) {
    let feedback = state.dashboard.pages.feedbacks.one

    if (!feedback._id) return {}

    let data = { _id: feedback._id, image: feedback.image, imageReview: feedback.imageReview }

    data.translations = feedback.translations.map((translation) => {
      let _translation = {
        _id: translation._id,
        lang: translation.lang,
        name: translation.name,
        country: translation.country,
        text: translation.text,
      }

      return _translation
    })

    return data
  },

  /* For Edit Faq */
  oldFaq(state) {
    let faq = state.dashboard.pages.faqs.one

    if (!faq._id) return {}

    let data = { _id: faq._id, image: faq.image, imageReview: faq.imageReview }

    data.translations = faq.translations.map((translation) => {
      let _translation = {
        _id: translation._id,
        lang: translation.lang,
        question: translation.question,
        answer: translation.answer,
      }

      return _translation
    })

    return data
  },
}
