<template>
  <div v-if="layout">
    <component :is="layout.component">
      <slot></slot>
    </component>
  </div>
  <div v-else>
    <slot></slot>
  </div>
</template>

<script>
import GuestLayout from '@/components/layouts/GuestLayout.vue'
import DashboardLayout from '@/components/layouts/DashboardLayout.vue'

export default {
  name: 'MainLayout',

  props: { type: { type: String, default: 'guest' } },

  components: { GuestLayout, DashboardLayout },

  data() {
    return {
      layouts: {
        guest: { component: GuestLayout },
        dashboard: { component: DashboardLayout },
      },
    }
  },

  computed: {
    layout() {
      if (this.$route && this.$route.meta) {
        if (typeof this.$route.meta.layout != 'undefined') return this.$route.meta.layout
      }

      let type = this.$route.name?.startsWith('dashboard') ? 'dashboard' : 'guest'

      return this.layouts[type]
    },
  },
}
</script>
