export default {
  app: {
    lang: 'en',
    baseAlert: { type: 'success', message: '', timeout: null },
  },
  auth: {
    isLoggedIn: false,
    token: null,
    me: {},
  },
  dashboard: {
    users: { count: 0, results: [], one: {}, options: [] },
    staff: { count: 0, results: [], one: {}, options: [] },
    labs: { count: 0, results: [], one: {}, options: [] },
    payments: { count: 0, results: [] },
    logs: {
      users: { count: 0, results: [], one: {}, actions: [] },
      staff: { count: 0, results: [], one: {}, actions: [] },
    },
    roles: { count: 0, results: [], one: {}, options: [] },
    permissions: { count: 0, results: [] },
    gallery: {
      labs: { results: [] },
      slides: { results: [] },
      sections: { results: [] },
      feedbacks: { results: [] },
      faqs: { results: [] },
      enterprises: { results: [] },
    },
    pages: {
      slides: { count: 0, results: [], one: {}, options: [] },
      sections: { count: 0, results: [], one: {}, options: [] },
      feedbacks: { count: 0, results: [], one: {}, options: [] },
      faqs: { count: 0, results: [], one: {}, options: [] },
      home: {},
      enterprise: {},
      contact: {},
      openVpn: {},
      terms: {},
      privacy: {},
      footer: {},
    },
  },
}
