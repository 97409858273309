<template>
  <b-navbar-brand :to="redirectTo" class="user-select-none">
    <span class="text-white h2 mb-0 font-weight-bold" :style="aStyle">C</span>
    <span class="h3 mb-0 font-weight-bold text-primary" :style="uctionStyle">yber Labs</span>
  </b-navbar-brand>
</template>

<script>
export default {
  props: {
    aStyle: { type: [Object, String], default: '' },
    uctionStyle: { type: [Object, String], default: '' },
    noRedirect: { type: Boolean, default: false },
    to: { type: [Object, String], default: () => ({ name: 'home' }) },
  },

  computed: {
    redirectTo() {
      if (this.noRedirect) return null

      return this.to
    },
  },
}
</script>

<style></style>
