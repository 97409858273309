<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    fill="currentColor"
    stroke="currentColor"
    :stroke-width="strokeWidth"
    viewBox="43 348.906 29.218 33"
  >
    <path
      d="M59.837 367.63h9.965a2.416 2.416 0 0 1 2.416 2.416v9.436a2.416 2.416 0 0 1-2.416 2.416h-9.965a2.416 2.416 0 0 1-2.416-2.416v-9.444a2.416 
      2.416 0 0 1 2.416-2.416Zm6.443-8.923h2.115a1.83 1.83 0 0 1 1.847 1.847v4.515h-1.61v-4.507a.268.268 0 0 0-.076-.188.268.268 0 0 
      0-.19-.075h-2.078v4.768h-1.694v-6.765h-7.042a.851.851 0 0 1-.861-.851v-6.856H44.697v25.63h10.05v1.695h-7.353v2.126c0 
      .148.12.268.269.268h7.079v1.592h-7.074a1.88 1.88 0 0 1-1.857-1.86v-2.115h-1.292A1.52 1.52 0 0 1 43 376.414v-25.991a1.517 1.517 0 0 1 
      1.52-1.517h13.032a.83.83 0 0 1 .65.312l7.47 7.248c.362.102.612.43.616.805v1.42Zm-17.911 8.156a.537.537 0 0 0-.52.566.537.537 0 0 0 
      .52.577h6.362v-1.143h-6.362Zm0 4.096a.537.537 0 0 0-.516.572.537.537 0 0 0 .516.572h6.362v-1.144h-6.362Zm0-12.292a.537.537 0 0 
      0-.537.572.537.537 0 0 0 .537.571h5.876a.537.537 0 0 0 .513-.571.537.537 0 0 0-.513-.572H48.37Zm0-4.097a.537.537 0 0 0-.516.572.537.537 0 0 0 
      .516.572h3.221a.537.537 0 0 0 .513-.572.537.537 0 0 0-.513-.572H48.37Zm0 8.193a.55.55 0 0 0-.537.575.537.537 0 0 0 .537.572H58.3a.55.55 0 0 0 
      .537-.572.537.537 0 0 0-.537-.575H48.37Zm10.04-10.888 5.057 4.733h-5.07v-4.733Zm4.166 21.648 1.27 
      1.194 3.03-3.073c.269-.269.438-.494.771-.153l1.074 1.103c.354.349.335.553 0 .88l-4.26 4.196c-.704.69-.583.733-1.294.024l-2.416-2.392a.311.311 
      0 0 1 .026-.485l1.251-1.297c.191-.196.341-.183.537 0Z"
      fill="currentColor"
      fill-rule="evenodd"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: { type: [Number, String], default: '29.218' },
    height: { type: [Number, String], default: 33 },
    strokeWidth: { type: [Number, String], default: 0 },
  },
}
</script>
