import router from '@/router'
import Cookies from 'js-cookie'
import axios from '@/plugins/axios'

export default {
  async FIRE_MESSAGE(state, options = {}) {
    let messages = { success: 'Saved Successfully', danger: 'Something Went Wrong', warning: 'warning' }

    let defaults = { type: 'success', message: messages.success, timeout: 5000 }

    let { type, message, timeout } = { ...defaults, ...options }

    message = options.message || messages[type]

    state.app.baseAlert.type = type

    state.app.baseAlert.message = message

    let isTherePreviousAlert = state.app.baseAlert.timeout != null

    if (state.app.baseAlert.timeout) {
      clearTimeout(state.app.baseAlert.timeout)

      state.app.baseAlert.timeout = null
    }

    isTherePreviousAlert && (await new Promise((resolve) => setTimeout(resolve, 200)))

    state.app.baseAlert.timeout = setTimeout(() => this.commit('REMOVE_FIRED_MESSAGE'), timeout)
  },

  REMOVE_FIRED_MESSAGE(state) {
    state.app.baseAlert.timeout = null
    state.app.baseAlert.message = null
  },

  async SET_TOKEN(state, { token, rememberMe = false, setCookie = true, pushToDashboard = true }) {
    state.auth.isLoggedIn = true

    state.auth.token = token

    axios.defaults.headers.Authorization = 'Bearer ' + token

    if (setCookie) {
      if (rememberMe) Cookies.set('token', token, { expires: new Date(Date.now() + 30758400000) })
      else Cookies.set('token', token)
    }

    pushToDashboard && router.push({ name: 'dashboard' })
  },

  REMOVE_TOKEN(state) {
    state.auth.isLoggedIn = false

    state.auth.token = null

    delete axios.defaults.headers.Authorization

    Cookies.remove('token')

    router.push({ name: 'login' })
  },

  SET_ME(state, data) {
    state.auth.me = data
  },

  SET_STAFF(state, data) {
    state.dashboard.staff.results = data.results
    state.dashboard.staff.count = data.count
  },

  SET_STAFF_OPTIONS(state, data) {
    state.dashboard.staff.options = data
  },

  SET_ONE_STAFF(state, data) {
    state.dashboard.staff.one = data
  },

  DELETE_STAFF(state, staffId) {
    state.dashboard.staff.results = state.dashboard.staff.results.filter((staff) => staff._id != staffId)
  },

  SET_USERS(state, data) {
    state.dashboard.users.results = data.results
    state.dashboard.users.count = data.count
  },

  SET_USER_OPTIONS(state, data) {
    state.dashboard.users.options = data
  },

  SET_ONE_USER(state, data) {
    state.dashboard.users.one = data
  },

  DELETE_USER(state, userId) {
    state.dashboard.users.results = state.dashboard.users.results.filter((user) => user._id != userId)
  },

  SET_ROLES(state, data) {
    state.dashboard.roles.results = data.results
    state.dashboard.roles.count = data.count
  },

  SET_ROLES_OPTIONS(state, data) {
    state.dashboard.roles.options = data
  },

  SET_ROLE(state, data) {
    state.dashboard.roles.one = data
  },

  SET_PERMISSIONS(state, data) {
    state.dashboard.permissions.results = data.results
    state.dashboard.permissions.count = data.count
  },

  SET_LABS(state, data) {
    state.dashboard.labs.results = data.results
    state.dashboard.labs.count = data.count
  },

  SET_LABS_OPTIONS(state, data) {
    state.dashboard.labs.options = data
  },

  SET_LAB(state, data) {
    state.dashboard.labs.one = data
  },

  DELETE_LAB(state, labId) {
    state.dashboard.labs.results = state.dashboard.labs.results.filter((lab) => lab._id != labId)
  },

  SET_GALLERY(state, { data, module }) {
    state.dashboard.gallery[module].results = data.results
  },

  SET_SLIDES(state, data) {
    state.dashboard.pages.slides.results = data.results
    state.dashboard.pages.slides.count = data.count
  },

  SET_SLIDE(state, data) {
    state.dashboard.pages.slides.one = data
  },

  DELETE_SLIDE(state, slideId) {
    state.dashboard.pages.slides.results = state.dashboard.pages.slides.results.filter((slide) => slide._id != slideId)
  },

  SET_SECTIONS(state, data) {
    state.dashboard.pages.sections.results = data.results
    state.dashboard.pages.sections.count = data.count
  },

  SET_SECTION(state, data) {
    state.dashboard.pages.sections.one = data
  },

  DELETE_SECTION(state, sectionId) {
    state.dashboard.pages.sections.results = state.dashboard.pages.sections.results.filter((section) => section._id != sectionId)
  },

  SET_FEEDBACKS(state, data) {
    state.dashboard.pages.feedbacks.results = data.results
    state.dashboard.pages.feedbacks.count = data.count
  },

  SET_FEEDBACK(state, data) {
    state.dashboard.pages.feedbacks.one = data
  },

  DELETE_FEEDBACK(state, feedbackId) {
    state.dashboard.pages.feedbacks.results = state.dashboard.pages.feedbacks.results.filter((feedback) => feedback._id != feedbackId)
  },

  SET_FAQS(state, data) {
    state.dashboard.pages.faqs.results = data.results
    state.dashboard.pages.faqs.count = data.count
  },

  SET_FAQ(state, data) {
    state.dashboard.pages.faqs.one = data
  },

  DELETE_FAQ(state, faqId) {
    state.dashboard.pages.faqs.results = state.dashboard.pages.faqs.results.filter((faq) => faq._id != faqId)
  },

  SET_HOME_PAGE(state, data) {
    state.dashboard.pages.home = data.page
  },

  SET_ENTERPRISE_PAGE(state, data) {
    state.dashboard.pages.enterprise = data.page
  },

  SET_CONTACT_PAGE(state, data) {
    state.dashboard.pages.contact = data.page
  },

  SET_OPEN_VPN_PAGE(state, data) {
    state.dashboard.pages.openVpn = data.page
  },

  SET_TERMS_PAGE(state, data) {
    state.dashboard.pages.terms = data.page
  },

  SET_PRIVACY_PAGE(state, data) {
    state.dashboard.pages.privacy = data.page
  },

  SET_FOOTER(state, data) {
    state.dashboard.pages.footer = data.footer
  },

  SET_USERS_LOGS(state, data) {
    state.dashboard.logs.users.results = data.results
    state.dashboard.logs.users.count = data.count
  },

  SET_STAFF_LOGS(state, data) {
    state.dashboard.logs.staff.results = data.results
    state.dashboard.logs.staff.count = data.count
  },

  SET_LOGS_ACTIONS(state, data) {
    state.dashboard.logs.users.actions = data.users
    state.dashboard.logs.staff.actions = data.staff
  },

  SET_PAYMENTS(state, data) {
    state.dashboard.payments.results = data.results
    state.dashboard.payments.count = data.count
  },
}
