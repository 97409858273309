<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    :width="width"
    :height="height"
    viewBox="0 0 35 32"
    enable-background="new 0 0 35 32"
    xml:space="preserve"
    stroke="currentColor"
    fill="currentColor"
    :stroke-width="strokeWidth"
  >
    <g>
      <path
        d="M17,23.898c-0.276,0-0.5,0.224-0.5,0.5v2.652c-1,0.232-1.75,1.242-1.75,2.449c0,1.378,1.247,2.5,2.625,2.5
        s2.312-1.122,2.312-2.5c0-1.207-0.188-2.217-2.188-2.449v-2.652C17.5,24.122,17.276,23.898,17,23.898z M19,29.5
        c0,0.827-0.673,1.5-1.5,1.5S16,30.327,16,29.5s0.673-1.5,1.5-1.5S19,28.673,19,29.5z"
      />
      <path
        d="M33,29.5c0-0.276-0.224-0.5-0.5-0.5h-10c-0.276,0-0.5,0.224-0.5,0.5s0.224,0.5,0.5,0.5h10
		    C32.776,30,33,29.776,33,29.5z"
      />
      <path d="M12.5,29h-10C2.224,29,2,29.224,2,29.5S2.224,30,2.5,30h10c0.276,0,0.5-0.224,0.5-0.5S12.776,29,12.5,29z" />
      <path
        d="M0,4.5C0,6.981,2.087,9,4.652,9h25.696C32.913,9,35,6.981,35,4.5S32.913,0,30.348,0H4.652
		    C2.087,0,0,2.019,0,4.5z M4.652,1h25.696C32.362,1,34,2.57,34,4.5S32.362,8,30.348,8H4.652C2.638,8,1,6.43,1,4.5S2.638,1,4.652,1z"
      />
      <path
        d="M4.843,6.589c1.103,0,2-0.897,2-2s-0.897-2-2-2s-2,0.897-2,2S3.74,6.589,4.843,6.589z M4.843,3.589
		    c0.551,0,1,0.449,1,1s-0.449,1-1,1s-1-0.449-1-1S4.292,3.589,4.843,3.589z"
      />
      <path d="M22.049,5h7c0.276,0,0.5-0.224,0.5-0.5S29.325,4,29.049,4h-7c-0.276,0-0.5,0.224-0.5,0.5S21.772,5,22.049,5z" />
      <path
        d="M4.652,22h25.696C32.913,22,35,19.981,35,17.5S32.913,13,30.348,13H4.652C2.087,13,0,15.019,0,17.5
        S2.087,22,4.652,22z M4.652,14h25.696C32.362,14,34,15.57,34,17.5S32.362,21,30.348,21H4.652C2.638,21,1,19.43,1,17.5
        S2.638,14,4.652,14z"
      />
      <path
        d="M4.843,19.437c1.103,0,2-0.897,2-2s-0.897-2-2-2s-2,0.897-2,2S3.74,19.437,4.843,19.437z M4.843,16.437 
        c0.551,0,1,0.449,1,1s-0.449,1-1,1s-1-0.449-1-1S4.292,16.437,4.843,16.437z"
      />
      <path d="M22.049,18h7c0.276,0,0.5-0.224,0.5-0.5s-0.224-0.5-0.5-0.5h-7c-0.276,0-0.5,0.224-0.5,0.5 S21.772,18,22.049,18z" />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: { type: [Number, String], default: 32 },
    height: { type: [Number, String], default: 32 },
    strokeWidth: { type: [Number, String], default: 0 },
  },
}
</script>
