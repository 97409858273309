<template>
  <svg
    :width="width"
    :height="height"
    fill="currentColor"
    stroke="currentColor"
    :stroke-width="strokeWidth"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1_104)">
      <path
        d="M7.32169 13.2746C8.27777 13.9521 9.3595 14.3102 10.4504 14.3102C11.8792 14.3102 13.2869 13.7092 14.4137 12.6176C14.7154 12.3254 15.0305 
        11.9845 15.115 11.8837C15.1497 11.8418 15.1821 11.7929 15.2109 11.7391C16.2922 9.69596 16.1119 7.58288 14.7157 5.94165C14.3831 5.55048 
        14.0883 5.21923 13.9822 5.11691C13.9298 5.06659 13.8658 5.02162 13.7916 4.98368C12.8179 4.48295 11.8318 4.229 10.8615 4.229C9.52769 4.229 
        8.23955 4.70194 7.03332 5.63529C6.98097 5.67566 6.93627 5.71928 6.90076 5.76493C6.8144 5.87652 6.53462 6.25843 6.28655 
        6.62315C4.68455 8.97192 5.11034 11.7072 7.32169 13.2746ZM7.97296 6.89252C7.99649 6.86763 8.0176 6.84153 8.03646 6.81522C8.13608 6.67732 
        8.50953 6.36291 8.92491 6.15441C9.8609 5.68378 10.9085 5.58301 11.9517 5.86117C12.4426 5.99203 13.1212 6.41113 13.4641 6.79547C14.3489 
        7.78638 14.6284 8.94027 14.2718 10.1324C14.1463 10.5516 13.9283 10.9704 13.8392 11.0633C13.0329 12.1475 11.8977 12.7381 10.6321 
        12.7381C9.08763 12.7381 7.6219 11.8387 7.0676 10.5509C6.76943 9.85739 6.48991 8.45916 7.97296 6.89252Z"
        fill="currentColor"
      />
      <path
        d="M21.568 7.78792C22.2839 8.295 23.0948 8.56322 23.9128 8.56322C24.9832 8.56322 26.0372 8.11383 26.88 7.29734C27.1073 7.07754 27.3363 
        6.82927 27.4006 6.75218C27.4301 6.7166 27.4573 6.67623 27.4806 6.632C28.2914 5.10053 28.1551 3.51543 27.107 2.28329C26.8614 1.99465 26.6427 
        1.74875 26.563 1.67179C26.5191 1.62925 26.4651 1.59158 26.4033 1.55986C25.6767 1.18621 24.94 0.996643 24.2144 0.996643C23.2161 
        0.996643 22.2535 1.34967 21.3527 2.04632C21.3088 2.08047 21.2713 2.1174 21.2411 2.15635C21.1769 2.23859 20.9705 2.52074 20.7868 
        2.79031C19.5847 4.55429 19.9054 6.60948 21.568 7.78792ZM22.1538 3.09113C22.174 3.06948 22.1916 3.04784 22.2077 3.02552C22.2675 2.94295 
        22.5264 2.71781 22.83 2.56605C23.4997 2.2298 24.2476 2.1577 24.9943 2.35674C25.3411 2.44925 25.8212 2.74561 26.0646 3.01795C26.7031 3.73313 
        26.8972 4.53109 26.6402 5.38944C26.5428 5.71481 26.3872 5.99263 26.3422 6.0397C25.7591 6.82217 24.9492 7.24404 24.0468 7.24404C22.9448 
        7.24404 21.8997 6.60407 21.5055 5.68803C21.2939 5.19751 21.0971 4.20755 22.1538 3.09113Z"
        fill="currentColor"
      />
      <path
        d="M31.5804 10.909C31.5701 10.8158 31.5395 10.7258 31.4893 10.6409C30.9423 9.71246 30.0044 9.24974 28.8752 9.38798C28.8711 9.38832 28.8673 
        9.38906 28.8639 9.38967C28.8481 9.38798 28.832 9.38662 28.8145 9.38662C27.9896 9.38662 27.1629 9.37756 26.3357 9.36823C25.5063 9.35917 
        24.6759 9.35004 23.8462 9.35004C22.3153 9.35004 20.2987 9.37749 18.2697 9.59195C18.1873 9.58525 18.0991 9.59472 18.0125 9.62116C17.2233 
        9.86206 16.587 10.4074 16.2662 11.1178C16.2344 11.1879 16.2127 11.2606 16.2008 11.3341C16.1625 11.4181 16.142 11.51 16.1402 11.607C16.1322 
        12.0282 16.122 12.4496 16.1122 12.8704C16.0978 13.4824 16.0854 14.1022 16.076 14.7244C16.134 14.7249 16.1924 14.7256 16.2505 
        14.7259C16.3096 14.7262 16.3686 14.727 16.4276 14.7276C16.6454 14.7296 16.8628 14.7333 17.0801 14.7377C17.1385 14.7391 17.1969 
        14.7424 17.255 14.7438C17.3137 14.7451 17.3727 14.748 17.4314 14.7498C17.4404 14.1059 17.4534 13.4643 17.4679 12.8314C17.4768 12.4506 
        17.4854 12.0693 17.4932 11.6885C17.6811 11.3197 17.963 11.0688 18.3318 10.9426C20.3388 10.732 22.3365 10.705 23.8544 10.705C24.6817 10.705 
        25.5097 10.7141 26.3366 10.7232C27.1495 10.7323 27.9616 10.7402 28.7512 10.7402C28.8034 10.7464 28.8597 10.7479 28.9195 10.7402C29.0218 
        10.7279 29.1207 10.7208 29.2166 10.7208C29.7258 10.7208 30.0765 10.9132 30.3199 11.3264C30.3327 11.3477 30.346 11.3674 30.3598 
        11.3862C30.5325 13.6418 30.6242 15.9781 30.6403 18.5215C30.0744 18.5229 29.4346 18.5297 28.7941 18.5586C28.7553 17.1031 28.6807 15.2284 
        28.6138 14.0251C28.5898 13.5985 28.2408 13.3751 27.9083 13.3751C27.7174 13.3751 27.5388 13.4476 27.4197 13.5735C27.3043 13.6956 27.249 
        13.8583 27.2589 14.0443C27.3392 15.475 27.4372 18.005 27.4623 19.6242C26.3873 19.6194 25.3121 19.6091 24.2372 19.5991C23.4871 19.5916 
        22.7371 19.5868 21.9864 19.5813C21.9929 19.64 21.9988 19.6987 22.0049 19.7577C22.0104 19.8161 22.0148 19.8748 22.0201 19.9336C22.0365 
        20.1273 22.0499 20.3217 22.0596 20.5164C22.0627 20.5751 22.0667 20.6335 22.0692 20.6922C22.0716 20.7509 22.0726 20.8093 22.075 
        20.8681C22.7966 20.8739 23.5185 20.876 24.2403 20.8836C25.5824 20.8973 26.9238 20.911 28.2658 20.9128H28.2669C28.5004 20.9128 28.6968 
        20.8019 28.8061 20.6085C28.9149 20.4155 28.9198 20.1628 28.8213 19.9639L28.8199 19.8423C29.5469 19.8097 30.2708 19.8053 30.9488 
        19.8053C31.0741 19.8053 31.1898 19.7723 31.2877 19.7105C31.2998 19.7109 31.3117 19.7112 31.3238 19.7112C31.5133 19.7112 31.6933 19.6401 
        31.8169 19.5154C31.9371 19.3952 32.0003 19.2345 31.9999 19.0505C31.9941 16.2793 31.8983 13.7315 31.707 11.2622C31.6971 11.1315 31.6535 
        11.0101 31.5804 10.909ZM28.8182 9.56239V9.56273V9.56239Z"
        fill="currentColor"
      />
      <path
        d="M19.8842 15.3102C20.1287 15.4779 20.3468 15.6651 20.5401 15.8701C20.5995 15.933 20.6624 15.9943 20.7173 16.0604C20.7807 16.1369 20.8371 
        16.2183 20.8952 16.299C20.8883 15.4741 20.8825 14.698 20.8814 14.1184C20.88 13.4404 20.0542 13.3085 19.7094 13.6535C19.5895 13.7738 19.5264 
        13.9349 19.5267 14.1192C19.5274 14.4015 19.5287 14.7316 19.5311 15.0903C19.5916 15.1238 19.65 15.1583 19.7076 15.1939C19.7681 15.231 
        19.8261 15.2705 19.8842 15.3102Z"
        fill="currentColor"
      />
      <path
        d="M21.0443 21.3031C21.0408 21.1554 21.0371 21.0085 21.0329 20.8615C21.0312 20.8024 21.0298 20.744 21.0281 20.6856C21.0264 20.6265 21.023 
        20.5682 21.0209 20.5098C21.0138 20.315 21.0072 20.1207 20.9966 19.9267C20.9935 19.8675 20.9873 19.8095 20.9839 19.7507C20.9804 19.6917 
        20.9745 19.6327 20.9708 19.5743C20.9602 19.4246 20.952 19.2748 20.9375 19.1258C20.9324 19.0695 20.9238 19.0117 20.9179 18.9547C20.8884 
        18.6807 20.8513 18.4011 20.7888 18.1264C20.7733 18.0577 20.7524 17.9904 20.7339 17.9231C20.6855 17.748 20.6282 17.5763 20.554 
        17.4125C20.4589 17.2029 20.3414 17.0048 20.191 16.8269C20.1072 16.728 20.0045 16.6346 19.895 16.545C19.838 16.4983 19.7803 16.4519 19.7181 
        16.408C19.6611 16.3685 19.6017 16.3304 19.5413 16.2936C19.4149 16.2163 19.2827 16.1456 19.146 16.0833C18.9279 15.983 18.7023 15.9049 18.4811 
        15.8504C18.3187 15.8104 18.1586 15.7806 18.0086 15.7699C17.815 15.7566 17.6195 15.752 17.4238 15.7488C17.366 15.7478 17.3084 15.7454 
        17.2507 15.7454C17.1909 15.7451 17.1312 15.7468 17.0714 15.7468C16.8533 15.7478 16.6356 15.7499 16.4172 15.7552C16.3588 15.7566 16.3001 
        15.758 16.2417 15.7595C16.183 15.7609 16.1242 15.7622 16.0655 15.7636C15.7749 15.7703 15.4847 15.776 15.197 15.7735C14.692 15.7689 14.187 
        15.7633 13.6817 15.758C13.1813 15.7524 12.6811 15.7472 12.1808 15.7425C10.1692 15.725 8.15606 15.7253 6.14591 15.8156C5.01738 15.8663 
        3.89027 15.9451 2.76681 16.0643C2.68065 16.0545 2.58664 16.0615 2.48554 16.0924C1.50856 16.3909 0.679293 17.0615 0.255189 18.0008C0.216167 
        18.0873 0.190806 18.1774 0.179985 18.2673C0.135688 18.3538 0.107216 18.4548 0.105119 18.573C0.0410742 21.8393 -0.0947933 25.1014 0.105119 
        28.3652C0.122297 28.6465 0.275613 28.8368 0.472888 28.9374C0.573859 29.0336 0.712431 29.0974 0.892731 29.0974C2.1852 29.0974 3.47712 
        29.0916 4.76837 29.0318C4.77128 29.4648 4.77304 29.8673 4.77371 30.2146C4.77405 30.3351 4.80151 30.4374 4.8458 30.525C4.92338 30.7465 
        5.1149 30.9234 5.42032 30.9237C9.07413 30.9279 12.7276 30.9993 16.3813 31.0035C16.9529 31.0041 17.1249 30.3867 16.899 30.0127C16.8979 
        29.8684 16.8959 29.7153 16.8938 29.5567C17.9402 29.5034 18.9873 29.4949 20.0354 29.4945C20.2009 29.4945 20.3315 29.4409 20.43 
        29.3585C20.8057 29.4138 21.2288 29.1978 21.2278 28.7066C21.223 26.5682 21.1378 24.4365 21.0715 22.2998C21.0594 21.968 21.0521 21.6355 
        21.0443 21.3031ZM16.8663 28.2865C16.8126 26.1521 16.7041 23.4758 16.6145 21.8744C16.5657 20.9988 15.1985 20.9939 15.2482 21.8744C15.3593 
        23.8642 15.5009 27.5224 15.5287 29.7278C12.3983 29.7161 9.26762 29.6673 6.13719 29.6564C6.12738 27.4591 6.08586 23.9226 6.08241 
        21.9885C6.08051 21.1073 4.71406 21.1073 4.71575 21.9885C4.71866 23.428 4.7422 25.7572 4.75836 27.7607C3.65411 27.8115 2.54945 27.8232 1.44438 
        27.8249C1.28951 24.7572 1.40847 21.69 1.46927 18.6203C1.74743 18.0468 2.18702 17.6288 2.79887 17.4271C4.73624 17.2228 6.68411 17.1394 8.6317 
        17.1112C9.861 17.0933 11.0908 17.0988 12.3202 17.1098C13.0453 17.1167 13.7705 17.1249 14.4953 17.1321C15.0224 17.138 15.5496 17.1335 16.0767 
        17.1294C16.1348 17.129 16.1928 17.1286 16.2512 17.1283C16.3103 17.1279 16.3694 17.1276 16.4285 17.1269C16.6204 17.1262 16.8121 17.1242 
        17.0041 17.1242C17.0298 17.1242 17.056 17.1242 17.0817 17.1234C17.1414 17.1234 17.2008 17.1234 17.2612 17.1227C17.3186 17.1227 17.3766 
        17.1234 17.4346 17.1242C17.6922 17.1262 17.9518 17.1423 18.1994 17.1956C18.4886 17.2581 18.7603 17.3728 18.9859 17.5933C19.1061 17.7107 
        19.1974  17.8343 19.271 17.9632C19.3139 18.038 19.3503 18.1146 19.3805 18.1936C19.4017 18.2499 19.4203 18.3069 19.4361 18.365C19.4522 
        18.4227 19.4653 18.4814 19.477 18.5411C19.5051 18.6901 19.5223 18.8453 19.5312 19.0099C19.5333 19.0459 19.5357 19.0823 19.5378 
        19.1188C19.5415 19.1771 19.5453 19.2355 19.5488 19.2939C19.5522 19.3536 19.5563 19.4127 19.5601 19.4725C19.5618 19.5038 19.5639 
        19.535 19.566 19.5659C19.5835 19.8454 19.5996 20.1247 19.6175 20.4045C19.6343 20.669 19.6514 20.9341 19.6648 21.1992C19.6652 21.2064 
        19.6659 21.2136 19.6662 21.2215C19.739 22.7106 19.7864 24.2 19.8228 25.6898C19.8434 26.5338 19.8509 27.378 19.8551 28.2221C18.8581 
        28.2243 17.8618 28.2351 16.8663 28.2865Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: { type: [Number, String], default: 32 },
    height: { type: [Number, String], default: 32 },
    strokeWidth: { type: [Number, String], default: 0 },
  },
}
</script>
