export default {
  computed: {
    API_URL() {
      return process.env.VUE_APP_API_URL
    },

    $me() {
      return this.$store.state.auth.me
    },

    $isLoggedIn() {
      return this.$store.state.auth.isLoggedIn
    },

    $isOwner() {
      return !!this.$store.state.auth.me.isOwner
    },
  },

  methods: {
    /**
     * Trigger a global error message
     * @param {object} options - { message: string, type: <success, danger, warning>, timeout: number }
     * @default
     * @param {string} options.message - default `Saved successfully` when the options.type is `succes` otherwise `Something went wrong`
     * @param {(success|danger|warning)} options.type - default `success`
     * @param {number} options.timeout - default `5000`
     */
    $fireMessage(options = {}) {
      this.$store.commit('FIRE_MESSAGE', options)
    },

    $hasPermission(permission) {
      return this.$isOwner || this.$me.permissions.include(permission)
    },
  },
}
