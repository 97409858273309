<template>
  <svg
    :width="width"
    :height="height"
    fill="currentColor"
    stroke="currentColor"
    :stroke-width="strokeWidth"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 64 64"
    enable-background="new 0 0 64 64"
    xml:space="preserve"
  >
    <g>
      <rect x="1" y="15" fill="none" stroke="currentColor" stroke-width="2" stroke-miterlimit="10" width="54" height="42" />
      <polyline fill="none" stroke="currentColor" stroke-width="2" stroke-miterlimit="10" points="11,12 11,7 63,7 63,50 58,50 	" />
      <line fill="none" stroke="currentColor" stroke-width="2" stroke-miterlimit="10" x1="1" y1="23" x2="55" y2="23" />
      <line fill="none" stroke="currentColor" stroke-width="2" stroke-miterlimit="10" x1="10" y1="19" x2="6" y2="19" />
      <line fill="none" stroke="currentColor" stroke-width="2" stroke-miterlimit="10" x1="18" y1="19" x2="14" y2="19" />
      <line fill="none" stroke="currentColor" stroke-width="2" stroke-miterlimit="10" x1="26" y1="19" x2="22" y2="19" />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: { type: [Number, String], default: 33 },
    height: { type: [Number, String], default: 33 },
    strokeWidth: { type: [Number, String], default: 0 },
  },
}
</script>
