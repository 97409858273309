<template>
  <div id="app">
    <main-layout>
      <BaseAlert />
      <router-view />
    </main-layout>
  </div>
</template>

<script>
import MainLayout from '@/components/layouts/MainLayout.vue'
import BaseAlert from '@/components/BaseAlert.vue'
export default {
  components: { MainLayout, BaseAlert },
}
</script>

<style lang="scss"></style>
