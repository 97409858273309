<template>
  <b-dropdown
    toggle-class="user_dropdown_toggler text-decoration-none outline-none d-flex align-items-center"
    menu-class="user_dropdown"
    right
    no-caret
  >
    <template #button-content>
      <div class="d-flex align-items-center">
        <b-avatar
          class="user_avatar"
          size="2rem"
          :src="$me.picture || 'https://ps.w.org/user-avatar-reloaded/assets/icon-256x256.png?rev=2540745'"
        ></b-avatar>
        <span class="h6 mb-0 ml-2 text-light text-capitalize d-none d-md-inline">{{ $me.username || $me.fullname }}</span>
      </div>
    </template>
    <b-dropdown-item to="/account" link-class="px-3 py-2" exact>
      <div class="d-flex align-items-center py-1">
        <div class="icon d-flex align-items-center justify-content-center">
          <b-icon-person></b-icon-person>
        </div>
        <h6 class="ml-2 mb-0">Account</h6>
      </div>
    </b-dropdown-item>
    <b-dropdown-item to="/account/change-password" link-class="px-3 py-2">
      <div class="d-flex align-items-center py-1">
        <div class="icon d-flex align-items-center justify-content-center">
          <b-icon-lock></b-icon-lock>
        </div>
        <h6 class="ml-2 mb-0">Change Password</h6>
      </div>
    </b-dropdown-item>
    <b-dropdown-item-btn button-class="px-3 py-2" variant="danger" @click="logout">
      <div class="d-flex align-items-center py-1">
        <div class="icon d-flex align-items-center justify-content-center">
          <b-icon-box-arrow-left></b-icon-box-arrow-left>
        </div>
        <h6 class="ml-2 mb-0">Logout</h6>
      </div>
    </b-dropdown-item-btn>
  </b-dropdown>
</template>

<script>
import { BIconBoxArrowLeft, BIconLock, BIconPerson } from 'bootstrap-vue'

export default {
  components: { BIconBoxArrowLeft, BIconLock, BIconPerson },

  methods: {
    logout() {
      this.$store.dispatch('logout')
    },
  },
}
</script>

<style lang="scss">
.user_avatar {
  border: 1px solid #fff;
  background-color: transparent !important;
  padding: 2px;
}
.user_dropdown_toggler.btn.dropdown-toggle {
  border: none;
  &:hover,
  &:focus,
  &:active {
    background-color: transparent !important;
    border: none;
    box-shadow: none;
    outline: none;
  }
}

ul.user_dropdown {
  li {
    a.dropdown-item {
      &:hover,
      &.router-link-active {
        color: var(--secondary);
        background-color: #e9ecef;
      }
    }
  }
}
</style>
