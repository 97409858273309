<template>
  <svg
    :width="width"
    :height="height"
    fill="currentColor"
    stroke="currentColor"
    :stroke-width="strokeWidth"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="24" y="21" width="2" height="5" />
    <rect x="20" y="16" width="2" height="10" />
    <path fill="currentColor" d="M11,26a5.0059,5.0059,0,0,1-5-5H8a3,3,0,1,0,3-3V16a5,5,0,0,1,0,10Z" />
    <path
      fill="currentColor"
      d="M28,2H4A2.002,2.002,0,0,0,2,4V28a2.0023,2.0023,0,0,0,2,2H28a2.0027,2.0027,0,0,
      0,2-2V4A2.0023,2.0023,0,0,0,28,2Zm0,9H14V4H28ZM12,4v7H4V4ZM4,28V13H28.0007l.0013,15Z"
    />
    <rect fill="none" width="32" height="32" />
  </svg>
</template>

<script>
export default {
  props: {
    width: { type: [Number, String], default: 32 },
    height: { type: [Number, String], default: 32 },
    strokeWidth: { type: [Number, String], default: 0 },
  },
}
</script>
