<template>
  <div class="dashboard_layout" :class="{ active_sidebar: isSidebarActive }">
    <b-navbar class="dashboard_navbar px-3 px-xl-0" toggleable="xl" type="dark" variant="secondary" fixed="top">
      <b-container fluid>
        <div class="menu_button" @click="isSidebarActive = !isSidebarActive">
          <BIconMenuButtonWide scale="1.4" v-if="isSidebarActive" />
          <BIconMenuApp scale="1.4" v-else />
        </div>

        <Logo :to="{ name: 'dashboard' }" class="d-none d-sm-block" />

        <div class="ml-auto d-flex align-items-center">
          <!-- <div role="button" class="d-none px-3 d-xl-block text-white">
						<BIconQuestionCircleFill scale="1.3" />
					</div> -->

          <!-- <NotificationButton /> -->

          <StaffAvatar class="ml-3" />
        </div>
      </b-container>
    </b-navbar>
    <div class="dashboard_body">
      <div class="dashboard_sidebar">
        <ul class="sidebar_list">
          <li class="sidebar_item" v-for="(item, i) in items" :key="i">
            <div v-if="item.children" class="sidebar_item_link" :class="{ active: item.isActive }">
              <span class="sidebar_item_icon"> <component :is="item.icon" /> </span>
              <span class="sidebar_item_text">{{ item.title }}</span>
            </div>
            <router-link v-else class="sidebar_item_link" :to="{ name: item.name }" exact active-class="active">
              <span class="sidebar_item_icon"> <component :is="item.icon" /> </span>
              <span class="sidebar_item_text">{{ item.title }}</span>
            </router-link>
          </li>
          <li class="sidebar_item mt-auto" @click="logout">
            <div class="sidebar_item_link sidebar_logout_btn" role="button" @click="logout">
              <span class="sidebar_item_icon"> <BIconBoxArrowLeft scale="2" /> </span>
              <span class="sidebar_item_text">Logout</span>
            </div>
          </li>
        </ul>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import Logo from '@/components/layouts/AppLogo.vue'
import StaffAvatar from '@/components/StaffAvatar.vue'
// import NotificationButton from "@/components/buttons/NotificationButton.vue";
import { BIconQuestionCircleFill, BIconMenuButtonWide, BIconMenuApp, BIconBoxArrowLeft } from 'bootstrap-vue'

import IconDashboard from '@/components/icons/DashboardIcon.vue'
import IconLabs from '@/components/icons/LabsIcon.vue'
import IconUsers from '@/components/icons/UsersIcon.vue'
import IconStaff from '@/components/icons/StaffIcon.vue'
import IconRoles from '@/components/icons/RolesIcon.vue'
import IconPages from '@/components/icons/PagesIcon.vue'
import IconLogs from '@/components/icons/LogsIcon.vue'
import IconSetting from '@/components/icons/SettingIcon.vue'
import IconAccount from '@/components/icons/AccountIcon.vue'
import IconPayment from '@/components/icons/PaymentIcon.vue'

export default {
  components: {
    Logo,
    StaffAvatar,
    // NotificationButton,
    BIconQuestionCircleFill,
    IconDashboard,
    IconLabs,
    IconUsers,
    IconStaff,
    IconRoles,
    IconPages,
    IconLogs,
    IconAccount,
    IconSetting,
    BIconMenuButtonWide,
    BIconMenuApp,
    BIconBoxArrowLeft,
    IconPayment,
  },

  data() {
    return {
      activeItem: null,
      isSidebarChildActive: false,
      isSidebarActive: false,
    }
  },

  computed: {
    items() {
      let items = [
        { name: 'dashboard', title: 'Dashboard', icon: IconDashboard },
        { name: 'dashboard.labs', title: 'Labs', icon: IconLabs },
        { name: 'dashboard.users', title: 'Users', icon: IconUsers },
        { name: 'dashboard.staff', title: 'Staff', icon: IconStaff },
        { name: 'dashboard.roles', title: 'Roles', icon: IconRoles },
        { name: 'dashboard.pages', title: 'Pages', icon: IconPages },
        { name: 'dashboard.logs', title: 'Logs', icon: IconLogs },
        { name: 'dashboard.settings', title: 'Settings', icon: IconSetting },
        { name: 'dashboard.payments', title: 'Payments', icon: IconPayment },
        { name: 'dashboard.account', title: 'Account', icon: IconAccount },
      ]

      return items
    },
  },

  watch: {
    '$route.name'() {
      this.hideSidebar()
    },
  },

  methods: {
    hideSidebar() {
      this.isSidebarChildActive = false
      this.isSidebarActive = false
    },

    logout() {
      this.$store.dispatch('logout')
    },
  },
}
</script>

<style lang="scss">
$navbarHeight: 80px;
$sidebarWidth: 115px;
$sidebarChildWidth: 350px;

.dashboard_layout {
  height: 100vh;
  overflow: hidden;
  .dashboard_navbar {
    height: $navbarHeight;
    .menu_button {
      color: var(--white);
      width: 30px;
      height: 30px;
      display: none;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-right: 12px;
      padding: 19px;
    }
  }
  .dashboard_body {
    height: calc(100% - $navbarHeight);
    padding-left: $sidebarWidth;
    margin-top: $navbarHeight;
    background-color: #e5e7e8;
    position: relative;
    .dashboard_sidebar {
      position: fixed;
      height: inherit;
      width: $sidebarWidth;
      top: $navbarHeight;
      left: 0;
      background-color: #fff;
      z-index: 1000;
      transition: all 0.2s ease-in-out;
      box-shadow: 0px 0px 0.6rem 0.2rem #bbbbbb80;
      overflow-y: auto;
      .sidebar_list {
        height: 100%;
        display: flex;
        flex-direction: column;
        padding-left: 0;
        list-style: none;
        margin: 0;
        background-color: #fff;
        z-index: 1;
        .sidebar_item {
          .sidebar_item_link {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 90px;
            justify-content: center;
            text-decoration: none;
            color: #999;
            font-weight: 600;
            font-size: 13px;
            cursor: pointer;
            // transition: all 0.1s ease-in-out;
            border-left: 3px solid transparent;
            .sidebar_item_icon {
              margin-bottom: 4px;
              transition: inherit;
            }
            .sidebar_item_text {
              transition: inherit;
            }
            &:hover,
            &.active {
              background-color: #eff1f2;
              font-weight: 700;
              box-shadow: -1px 0px 9px -1px #c0c0c0;
              border-left-color: var(--primary);
              .sidebar_item_icon {
                color: var(--primary);
              }
              .sidebar_item_text {
                color: #333;
              }
            }
            &.sidebar_logout_btn {
              &:hover {
                border-left-color: transparent;
                background-color: var(--danger);
                .sidebar_item_text {
                  color: var(--white);
                }
                svg {
                  fill: var(--white);
                }
              }
            }
          }
        }
      }
      .dashboard_sidebar_child {
        width: $sidebarChildWidth;
        position: fixed;
        background-color: var(--secondary);
        height: inherit;
        top: $navbarHeight;
        left: -$sidebarChildWidth;
        z-index: -1;
        transition: left 0.1s ease-in-out;
        overflow-y: auto;
        .dashboard_sidebar_child_header {
          padding: 16px;
          .dashboard_sidebar_child_title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .dashboard_sidebar_child_title_text {
              color: var(--white);
              font-weight: bold;
              font-size: 22px;
            }
            .close_icon {
              margin-left: auto;
              cursor: pointer;
              width: 20.8px;
              height: 20.8px;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              svg {
                background-color: var(--white);
                border-radius: 50%;

                &:hover {
                  transition: 0.1s;
                  transform: scale(1.15);
                }
              }
            }
          }
        }
        .dashboard_sidebar_child_body {
          .sidebar_child_list {
            display: flex;
            flex-direction: column;
            padding-left: 0;
            margin: 0;
            padding-bottom: 20px;
            list-style: none;
            .sidebar_child_item {
              display: flex;
              align-items: center;
              width: 100%;
              .sidebar_child_item_link {
                width: 100%;
                text-transform: uppercase;
                color: var(--white);
                font-size: 16px;
                text-decoration: none;
                padding: 16px;
                &:hover,
                &.active {
                  color: var(--danger);
                }
              }
            }
          }
        }
        &.active {
          left: $sidebarWidth;
        }
      }
    }
    .dashboard_content {
      height: 100%;
      overflow: auto;
      position: relative;
      .filter_icon_button {
        position: absolute;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        right: 15px;
        bottom: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--primary);
        color: var(--white);
        &:hover {
          transition: 0.1s;
          transform: scale(1.1);
          box-shadow: 0px 0px 0.6rem 0.2rem #bbbbbb80;
        }
      }
      .dashboard_siderbar_filter {
        position: absolute;
        top: 0;
        left: -100%;
        width: $sidebarChildWidth;
        height: calc(100vh - $navbarHeight);
        padding: 20px;
        background-color: #eff1f2;
        box-shadow: 0px 0px 0.6rem 0.2rem #bbbbbb80;
        transition: 0.2s ease-in-out;
        overflow-y: auto;
        .dashboard_siderbar_filter_header {
          font-size: 17px;
          letter-spacing: 1px;
          font-weight: bold;
          display: flex;
          align-items: center;
          justify-content: start;
          margin-bottom: 16px;
          .close_icon {
            justify-self: end;
            margin-left: auto;
            display: flex;
            position: relative;
            left: 5px;
            padding: 9px;

            &:hover {
              svg {
                transition: 0.1s;
                transform: scale(1.15);
              }
            }
          }
          .route_parent_name {
            color: var(--danger);
          }
          .route_child_name {
            color: var(--primary);
            font-size: 15px;
            white-space: nowrap;
          }
          .route_separetor {
            color: var(--dark);
            font-size: 18px;
            margin: 0 2px;
          }
        }
      }
      &.has_filters {
        .base_container {
          padding-left: 26px;
        }
      }
      &.active_filters {
        .dashboard_siderbar_filter {
          left: 0;
        }
      }
    }
  }
  @media (max-width: 767px) {
    .dashboard_navbar {
      .menu_button {
        display: flex;
      }
    }
    .dashboard_body {
      padding-left: 0;
      .dashboard_sidebar {
        left: -100%;
        .sidebar_list {
          .sidebar_item {
            .sidebar_item_link {
              flex-direction: row;
              justify-content: start;
              padding: 0 16px;
              .sidebar_item_icon {
                width: 47px;
              }
              .sidebar_item_text {
                font-size: 16px;
              }
            }
          }
        }
        .dashboard_sidebar_child {
          width: 100%;
          z-index: 1;
          left: -100%;
          &.active {
            left: 0;
          }
        }
      }
    }
    &.active_sidebar {
      .dashboard_body {
        .dashboard_sidebar {
          left: 0;
          width: 100%;
        }
      }
    }
  }

  @media (min-width: 1499px) {
    .dashboard_body {
      .dashboard_content {
        .filter_icon_button {
          display: none;
        }
        .dashboard_siderbar_filter {
          left: 0;
          padding: 27px 20px;
          .dashboard_siderbar_filter_header {
            font-size: 18px;
            .close_icon {
              display: none;
            }
            .route_child_name {
              font-size: 16px;
            }
          }
        }
        &.has_filters {
          .base_container {
            padding-left: calc($sidebarChildWidth + 20px);
          }
        }
      }
    }
  }

  @media (max-width: 576px) {
    .dashboard_body {
      .dashboard_content {
        .dashboard_siderbar_filter {
          width: 100%;
        }
      }
    }
  }
}
</style>
