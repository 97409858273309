import Vue from 'vue'

Vue.filter('capitalize', function (value) {
  if (!value) return ''

  return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('upperCase', (value) => (value && value.toString().toUpperCase()) || '')

Vue.filter('toSentenceCase', function (value, delimiter = '[A-Z]') {
  if (!value) return ''

  let regex = new RegExp(`(${delimiter})`, 'g')

  return value
    .toString()
    .replace(regex, ' $1')
    .replace(/^./, function (str) {
      return str.toLowerCase().toUpperCase()
    })
})
