<template>
  <svg
    :width="width"
    :height="height"
    fill="currentColor"
    stroke="currentColor"
    :stroke-width="strokeWidth"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 60 60"
    style="enable-background: new 0 0 60 60"
    xml:space="preserve"
  >
    <g>
      <g>
        <g>
          <path
            d="M2,18h44v6h2v-7c0-0.6-0.4-1-1-1h-6V4c0-2.2-1.8-4-4-4H11C8.8,0,7,1.8,7,4v12H1c-0.6,0-1,0.4-1,1v42c0,0.6,0.4,1,1,1h31
				v-2H2V18z M9,4c0-1.1,0.9-2,2-2h26c1.1,0,2,0.9,2,2v12H9V4z"
          />
          <path
            d="M53,43H43c-3.9,0-7,3.1-7,7v10h2V50c0-2.8,2.2-5,5-5h1.3l2.8,7.4c0.1,0.3,0.5,0.6,0.9,0.6s0.8-0.3,1.1-0.6l2.8-7.4H53
				c2.8,0,5,2.2,5,5v10h2V50C60,46.1,56.9,43,53,43z M48.1,49.2L46.5,45h3.2L48.1,49.2z"
          />
          <rect x="47" y="54" width="2" height="2" />
          <rect x="47" y="58" width="2" height="2" />
          <path
            d="M42,34v3c0,2.8,2.2,5,5,5h2c2.8,0,5-2.2,5-5v-3c0-2.8-2.2-5-5-5h-2C44.2,29,42,31.2,42,34z M52,34v3c0,1.7-1.3,3-3,3h-2
				c-1.7,0-3-1.3-3-3v-3c0-1.7,1.3-3,3-3h2C50.7,31,52,32.3,52,34z"
          />
          <rect x="41" y="53" width="2" height="7" />
          <rect x="53" y="53" width="2" height="7" />
          <path
            d="M24,23c-4.5,0-8,3.5-8,8c0,2.9,1.5,5.5,4,6.9V51c0,0.6,0.4,1,1,1h6c0.6,0,1-0.4,1-1V37.9c2.5-1.4,4-4,4-6.9
				C32,26.5,28.5,23,24,23z M26.6,36.4c-0.4,0.2-0.6,0.5-0.6,0.9V50h-4V37.3c0-0.4-0.2-0.7-0.6-0.9c-2.1-1-3.4-3-3.4-5.4
				c0-3.4,2.6-6,6-6s6,2.6,6,6C30,33.4,28.7,35.4,26.6,36.4z"
          />
          <rect x="22" y="30" width="4" height="2" />
          <rect x="6" y="21" width="2" height="18" />
          <rect x="6" y="42" width="2" height="4" />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: { type: [Number, String], default: '35.2px' },
    height: { type: [Number, String], default: '35.2px' },
    strokeWidth: { type: [Number, String], default: 0 },
  },
}
</script>
