<template>
  <svg
    :width="width"
    :height="height"
    fill="currentColor"
    stroke="currentColor"
    :stroke-width="strokeWidth"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21,10 L21,9 L3,9 L3,10 L21,10 Z M21,11 L3,11 L3,16.5 C3,17.3284271 3.67157288,18 4.5,18 L19.5,18 C20.3284271,18 21,17.3284271 
      21,16.5 L21,11 Z M21,8 L21,7.5 C21,6.67157288 20.3284271,6 19.5,6 L4.5,6 C3.67157288,6 3,6.67157288 3,7.5 L3,8 L21,8 Z M17.0001277,16.7325095 
      C16.2296154,17.1783089 15.2351019,17.063529 14.5857864,16.4142136 C13.8047379,15.633165 13.8047379,14.366835 14.5857864,13.5857864 
      C15.2340286,12.9375443 16.2281989,12.8209143 17.0001033,13.2675046 C17.2942533,13.0973715 17.6357555,13 18,13 C19.1045695,13 20,13.8954305 
      20,15 C20,16.1045695 19.1045695,17 18,17 C17.6357656,17 17.2942722,16.9026339 17.0001277,16.7325095 L17.0001277,16.7325095 Z 
      M16.2498202,15.9686777 C16.090641,15.6816907 16,15.3514305 16,15 C16,14.6485599 16.090646,14.3182912 16.2498333,14.0312988 
      C15.916851,13.9458211 15.5502017,14.0355847 15.2928932,14.2928932 C14.9023689,14.6834175 14.9023689,15.3165825 15.2928932,15.7071068 
      C15.5505379,15.9647514 15.9171928,16.0540405 16.2498202,15.9686777 L16.2498202,15.9686777 Z M2,7.5 C2,6.11928813 3.11928813,5 4.5,5 L19.5,5 
      C20.8807119,5 22,6.11928813 22,7.5 L22,16.5 C22,17.8807119 20.8807119,19 19.5,19 L4.5,19 C3.11928813,19 2,17.8807119 2,16.5 L2,7.5 Z M18,16 
      C18.5522847,16 19,15.5522847 19,15 C19,14.4477153 18.5522847,14 18,14 C17.4477153,14 17,14.4477153 17,15 C17,15.5522847 17.4477153,16 18,16 Z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: { type: [Number, String], default: 32 },
    height: { type: [Number, String], default: 32 },
    strokeWidth: { type: [Number, String], default: 0 },
  },
}
</script>
