import router from '@/router'
import store from '@/store'
import axios from 'axios'

axios.defaults.baseURL = process.env.VUE_APP_API_URL

let headers = { 'Content-Type': 'application/json' }

const axiosInstance = axios.create({ headers })

axiosInstance.interceptors.response.use(
  function (response) {
    let data = response.data

    if (data.message) {
      store.commit('FIRE_MESSAGE', { message: data.message })
    }

    return data
  },
  function (error) {
    let data = (error.response && error.response.data) || {}

    let status = error.response && error.response.status

    if (data.message) {
      store.commit('FIRE_MESSAGE', { type: 'danger', message: data.message })
    }

    if (status === 401 && router.history.current.meta.requireAuth) {
      store.commit('SET_ME', {})
      store.commit('REMOVE_TOKEN')
    }

    return Promise.reject(data)
  },
)

export default axiosInstance
