import axios from '@/plugins/axios'

import Cookies from 'js-cookie'

export default {
  async login({ commit }, payload) {
    let data = await axios.post('/auth/login/', payload)

    commit('SET_TOKEN', { token: data.token, rememberMe: payload.rememberMe })

    commit('SET_ME', data.me)

    return data
  },

  logout({ commit }) {
    commit('REMOVE_TOKEN')

    commit('SET_ME', {})
  },

  async sendResetPassword(_, payload) {
    let data = await axios.post('/auth/reset-password/', payload)

    return data
  },

  async changePassword({ dispatch, state }, payload) {
    let data = await axios.post('/auth/change-password', payload)

    if (state.auth.isLoggedIn) await dispatch('getMe')

    return data
  },

  async getMe({ commit }) {
    if (!Cookies.get('token')) return false

    try {
      let data = await axios.get('/auth/me/')
      commit('SET_ME', data.me)
      return data.me
    } catch (e) {
      commit('SET_ME', {})
      commit('REMOVE_TOKEN')
    }
  },

  async updateMe({ dispatch }, payload) {
    let data = await axios.put('/auth/me/', payload)

    await dispatch('getMe')

    return data
  },

  async getStaff({ commit }, query = '') {
    let data = await axios.get(`staff${query}`)

    commit('SET_STAFF', data)

    return data
  },

  async getStaffOptions({ commit }) {
    let data = await axios.get('staff/options')

    commit('SET_STAFF_OPTIONS', data.options)

    return data.options
  },

  async getOneStaff({ commit }, staffId) {
    let data = await axios.get(`staff/${staffId}`)

    commit('SET_ONE_STAFF', data.doc)

    return data.doc
  },

  async createStaff({ dispatch }, payload) {
    let data = await axios.post('staff/', payload)

    await dispatch('getStaff')

    return data
  },

  async updateStaff({ dispatch }, payload) {
    let data = await axios.put(`staff/${payload._id}/`, payload)

    await dispatch('getStaff')

    return data
  },

  changeStaffStatus(_, staff) {
    return axios.put(`staff/${staff._id}/status/`, { isActive: staff.isActive })
  },

  async deleteStaff({ commit }, payload) {
    let data = await axios.delete(`staff/${payload._id}/`, payload)

    commit('DELETE_STAFF', payload._id)

    return data
  },

  async getUsers({ commit }, query = '') {
    let data = await axios.get(`users/${query}`)

    if (data.results) commit('SET_USERS', data)

    return data
  },

  async createUser({ dispatch }, payload) {
    let data = await axios.post('users/', payload)

    await dispatch('getUsers')

    return data
  },

  async updateUser({ dispatch }, payload) {
    let data = await axios.put(`users/${payload._id}/`, payload)

    await dispatch('getUsers')

    return data
  },

  async adjustmentUserBalance({ dispatch }, payload) {
    let data = await axios.post(`users/${payload._id}/adjustment-balance`, payload)

    await dispatch('getUsers')

    return data
  },

  async changeUserStatus(_, user) {
    return axios.put(`users/${user._id}/status/`, { isActive: user.isActive })
  },

  async changeUserVerificationStatus(_, user) {
    return axios.put(`users/${user._id}/verification-status/`, { isVerified: user.isVerified })
  },

  async deleteUser({ commit }, payload) {
    let data = await axios.delete(`users/${payload._id}/`, payload)

    commit('DELETE_USER', payload._id)

    return data
  },

  async getRoles({ commit }, query = '') {
    let data = await axios.get(`roles${query}`)

    commit('SET_ROLES', data)

    return data
  },

  async getRoleOptions({ commit }) {
    let data = await axios.get('roles/options')

    commit('SET_ROLES_OPTIONS', data.options)

    return data.options
  },

  async getRole({ commit }, roleId) {
    let data = await axios.get(`roles/${roleId}`)

    commit('SET_ROLE', data.doc)

    return data.doc
  },

  async createRole({ dispatch }, payload) {
    let data = await axios.post('roles', payload)

    await dispatch('getRoles')

    return data
  },

  updateRole(_, role) {
    return axios.put(`roles/${role._id}`, role)
  },

  deleteRole(_, roleId) {
    return axios.delete(`roles/${roleId}`)
  },

  async getPermissions({ commit }) {
    let data = await axios.get('permissions')

    commit('SET_PERMISSIONS', data)

    return data
  },

  async getLabs({ commit }, query = '') {
    let data = await axios.get(`labs${query}`)

    commit('SET_LABS', data)

    return data
  },

  async getLabsOptions({ commit }) {
    let data = await axios.get('labs/options')

    commit('SET_LABS_OPTIONS', data.options)

    return data.options
  },

  async getLab({ commit }, labId) {
    let data = await axios.get(`labs/${labId}`)

    commit('SET_LAB', data.doc)

    return data.doc
  },

  createLab(_, payload) {
    return axios.post('labs/', payload)
  },

  updateLab(_, payload) {
    return axios.put(`labs/${payload._id}`, payload)
  },

  changeLabStatus(_, lab) {
    return axios.put(`labs/${lab._id}/status/`, { isActive: lab.isActive })
  },

  async deleteLab({ commit }, payload) {
    let data = await axios.delete(`labs/${payload._id}/`, payload)

    commit('DELETE_LAB', payload._id)

    return data
  },

  async getGallery({ commit }, payload) {
    let data = await axios.get(`gallery/${payload.module}`) // module: `labs`, `users` or `staff`, etc

    commit('SET_GALLERY', { data, module: payload.module })

    return data
  },

  async getSlides({ commit }) {
    let data = await axios.get('pages-content/slides')

    commit('SET_SLIDES', data)

    return data
  },

  async getSlide({ commit }, slideId) {
    let data = await axios.get(`pages-content/slides/${slideId}`)

    commit('SET_SLIDE', data.doc)

    return data.doc
  },

  createSlide(_, payload) {
    return axios.post('pages-content/slides/', payload)
  },

  updateSlide(_, payload) {
    return axios.put(`pages-content/slides/${payload._id}`, payload)
  },

  async deleteSlide({ commit }, payload) {
    let data = await axios.delete(`pages-content/slides/${payload._id}/`, payload)

    commit('DELETE_SLIDE', payload._id)

    return data
  },

  async getSections({ commit }) {
    let data = await axios.get('pages-content/sections')

    commit('SET_SECTIONS', data)

    return data
  },

  async getSection({ commit }, sectionId) {
    let data = await axios.get(`pages-content/sections/${sectionId}`)

    commit('SET_SECTION', data.doc)

    return data.doc
  },

  createSection(_, payload) {
    return axios.post('pages-content/sections/', payload)
  },

  updateSection(_, payload) {
    return axios.put(`pages-content/sections/${payload._id}`, payload)
  },

  async deleteSection({ commit }, payload) {
    let data = await axios.delete(`pages-content/sections/${payload._id}/`, payload)

    commit('DELETE_SECTION', payload._id)

    return data
  },

  async getFeedbacks({ commit }) {
    let data = await axios.get('pages-content/feedbacks')

    commit('SET_FEEDBACKS', data)

    return data
  },

  async getFeedback({ commit }, feedbackId) {
    let data = await axios.get(`pages-content/feedbacks/${feedbackId}`)

    commit('SET_FEEDBACK', data.doc)

    return data.doc
  },

  createFeedback(_, payload) {
    return axios.post('pages-content/feedbacks/', payload)
  },

  updateFeedback(_, payload) {
    return axios.put(`pages-content/feedbacks/${payload._id}`, payload)
  },

  async deleteFeedback({ commit }, payload) {
    let data = await axios.delete(`pages-content/feedbacks/${payload._id}/`, payload)

    commit('DELETE_FEEDBACK', payload._id)

    return data
  },

  async getFaqs({ commit }) {
    let data = await axios.get('pages-content/faqs')

    commit('SET_FAQS', data)

    return data
  },

  async getFaq({ commit }, faqId) {
    let data = await axios.get(`pages-content/faqs/${faqId}`)

    commit('SET_FAQ', data.doc)

    return data.doc
  },

  createFaq(_, payload) {
    return axios.post('pages-content/faqs/', payload)
  },

  updateFaq(_, payload) {
    return axios.put(`pages-content/faqs/${payload._id}`, payload)
  },

  async deleteFaq({ commit }, payload) {
    let data = await axios.delete(`pages-content/faqs/${payload._id}/`, payload)

    commit('DELETE_FAQ', payload._id)

    return data
  },

  async getHomePage({ commit }) {
    let data = await axios.get('pages/home')

    commit('SET_HOME_PAGE', data)

    return data
  },

  updateHomePage(_, payload) {
    return axios.put('pages/home', payload)
  },

  async getEnterprisePage({ commit }) {
    let data = await axios.get('pages/enterprise')

    commit('SET_ENTERPRISE_PAGE', data)

    return data
  },

  updateEnterprisePage(_, payload) {
    return axios.put('pages/enterprise', payload)
  },

  async getContactPage({ commit }) {
    let data = await axios.get('pages/contact')

    commit('SET_CONTACT_PAGE', data)

    return data
  },

  updateContactPage(_, payload) {
    return axios.put('pages/contact', payload)
  },

  async getOpenVpnPage({ commit }) {
    let data = await axios.get('pages/open-vpn')

    commit('SET_OPEN_VPN_PAGE', data)

    return data
  },

  updateOpenVpnPage(_, payload) {
    return axios.put('pages/open-vpn', payload)
  },

  async getTermsPage({ commit }) {
    let data = await axios.get('pages/terms')

    commit('SET_TERMS_PAGE', data)

    return data
  },

  updateTermsPage(_, payload) {
    return axios.put('pages/terms', payload)
  },

  async getPrivacyPage({ commit }) {
    let data = await axios.get('pages/privacy')

    commit('SET_PRIVACY_PAGE', data)

    return data
  },

  updatePrivacyPage(_, payload) {
    return axios.put('pages/privacy', payload)
  },

  async getFooter({ commit }) {
    let data = await axios.get('pages/footer')

    commit('SET_FOOTER', data)

    return data
  },

  updateFooter(_, payload) {
    return axios.put('pages/footer', payload)
  },

  async getUsersLogs({ commit }, query = '') {
    let data = await axios.get(`logs/users${query}`)

    if (data.results) commit('SET_USERS_LOGS', data)

    return data
  },

  async getStaffLogs({ commit }, query = '') {
    let data = await axios.get(`logs/staff${query}`)

    if (data.results) commit('SET_STAFF_LOGS', data)

    return data
  },

  async getLogsActions({ commit }) {
    let data = await axios.get('logs/actions')

    commit('SET_LOGS_ACTIONS', data)

    return data
  },

  async getLogField({ commit }, log) {
    let data = await axios.get(`logs/${log._id}/`)

    commit('SET_LOG', data)

    return data
  },

  async getPayments({ commit }, query = '') {
    let data = await axios.get(`payments${query}`)

    commit('SET_PAYMENTS', data)

    return data
  },
}
