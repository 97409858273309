import Vue from 'vue'

import VueRouter from 'vue-router'

Vue.use(VueRouter)

let getComponent = (path) => () => import(/* webpackChunkName: "[request]" */ `../views/${path}`)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: getComponent('auth/LoginView'),
    meta: { preventAuth: true /* layout: null */ },
  },
  {
    path: '/reset-password',
    name: 'resetPassword',
    component: getComponent('auth/ResetPasswordView'),
    meta: { preventAuth: true /* layout: null */ },
  },
  {
    path: '/set-password',
    name: 'setPassword',
    component: getComponent('auth/SetPasswordView'),
    meta: { preventAuth: true /* layout: null */ },
  },
  {
    path: '/',
    name: 'dashboard',
    component: getComponent('dashboard/DashboardView'),
    meta: { requireAuth: true },
  },
  {
    path: '/staff',
    name: 'dashboard.staff',
    component: getComponent('dashboard/StaffView'),
    meta: { requireAuth: true, authFor: ['read:staff'] },
  },
  {
    path: '/staff/create',
    name: 'dashboard.staff.create',
    component: getComponent('dashboard/StaffFormView'),
    meta: { requireAuth: true, authFor: ['create:staff'] },
  },
  {
    path: '/staff/:id/update',
    name: 'dashboard.staff.update',
    component: getComponent('dashboard/StaffFormView'),
    meta: { requireAuth: true, authFor: ['update:staff'] },
  },
  {
    path: '/labs',
    name: 'dashboard.labs',
    component: getComponent('dashboard/LabsView'),
    meta: { requireAuth: true, authFor: ['read:labs'] },
  },
  {
    path: '/labs/create',
    name: 'dashboard.labs.create',
    component: getComponent('dashboard/LabFormView'),
    meta: { requireAuth: true, authFor: ['create:labs'] },
  },
  {
    path: '/labs/:id/update',
    name: 'dashboard.labs.update',
    component: getComponent('dashboard/LabFormView'),
    meta: { requireAuth: true, authFor: ['update:labs'] },
  },
  {
    path: '/roles',
    name: 'dashboard.roles',
    component: getComponent('dashboard/RolesView'),
    meta: { requireAuth: true, authFor: ['read:roles'] },
  },
  {
    path: '/roles/create',
    name: 'dashboard.roles.create',
    component: getComponent('dashboard/RoleFormView'),
    meta: { requireAuth: true, authFor: ['create:roles'] },
  },
  {
    path: '/roles/:id/update',
    name: 'dashboard.roles.update',
    component: getComponent('dashboard/RoleFormView'),
    meta: { requireAuth: true, authFor: ['update:roles'] },
  },
  {
    path: '/pages',
    name: 'dashboard.pages',
    component: getComponent('dashboard/pages/PagesView'),
    meta: { requireAuth: true, authFor: ['update:pages'] },
  },
  {
    path: '/pages/home',
    name: 'dashboard.pages.home',
    component: getComponent('dashboard/pages/HomeFormView'),
    meta: { requireAuth: true, authFor: ['update:home'] },
  },
  {
    path: '/pages/enterprise-services',
    name: 'dashboard.pages.enterprise',
    component: getComponent('dashboard/pages/EnterpriseFormView'),
    meta: { requireAuth: true, authFor: ['update:enterprise'] },
  },
  {
    path: '/pages/contact-us',
    name: 'dashboard.pages.contact',
    component: getComponent('dashboard/pages/ContactFormView'),
    meta: { requireAuth: true, authFor: ['update:contact'] },
  },
  {
    path: '/pages/open-vpn',
    name: 'dashboard.pages.openVpn',
    component: getComponent('dashboard/pages/OpenVpnFormView'),
    meta: { requireAuth: true, authFor: ['update:openVpn'] },
  },
  {
    path: '/pages/terms-conditions',
    name: 'dashboard.pages.terms',
    component: getComponent('dashboard/pages/TermsFormView'),
    meta: { requireAuth: true, authFor: ['update:terms'] },
  },
  {
    path: '/pages/privacy-policy',
    name: 'dashboard.pages.privacy',
    component: getComponent('dashboard/pages/PrivacyFormView'),
    meta: { requireAuth: true, authFor: ['update:privacy'] },
  },
  {
    path: '/pages/footer',
    name: 'dashboard.pages.footer',
    component: getComponent('dashboard/pages/FooterFormView'),
    meta: { requireAuth: true, authFor: ['update:footer'] },
  },
  {
    path: '/pages/slides',
    name: 'dashboard.pages.slides',
    component: getComponent('dashboard/pages/SlidesView'),
    meta: { requireAuth: true, authFor: ['read:slides'] },
  },
  {
    path: '/pages/slides/create',
    name: 'dashboard.pages.slides.create',
    component: getComponent('dashboard/pages/SlideFormView'),
    meta: { requireAuth: true, authFor: ['create:slides'] },
  },
  {
    path: '/pages/slides/:id/update',
    name: 'dashboard.pages.slides.update',
    component: getComponent('dashboard/pages/SlideFormView'),
    meta: { requireAuth: true, authFor: ['update:slides'] },
  },
  {
    path: '/pages/sections',
    name: 'dashboard.pages.sections',
    component: getComponent('dashboard/pages/SectionsView'),
    meta: { requireAuth: true, authFor: ['read:sections'] },
  },
  {
    path: '/pages/sections/create',
    name: 'dashboard.pages.sections.create',
    component: getComponent('dashboard/pages/SectionFormView'),
    meta: { requireAuth: true, authFor: ['create:sections'] },
  },
  {
    path: '/pages/sections/:id/update',
    name: 'dashboard.pages.sections.update',
    component: getComponent('dashboard/pages/SectionFormView'),
    meta: { requireAuth: true, authFor: ['update:sections'] },
  },
  {
    path: '/pages/feedbacks',
    name: 'dashboard.pages.feedbacks',
    component: getComponent('dashboard/pages/FeedbacksView'),
    meta: { requireAuth: true, authFor: ['read:feedbacks'] },
  },
  {
    path: '/pages/feedbacks/create',
    name: 'dashboard.pages.feedbacks.create',
    component: getComponent('dashboard/pages/FeedbackFormView'),
    meta: { requireAuth: true, authFor: ['create:feedbacks'] },
  },
  {
    path: '/pages/feedbacks/:id/update',
    name: 'dashboard.pages.feedbacks.update',
    component: getComponent('dashboard/pages/FeedbackFormView'),
    meta: { requireAuth: true, authFor: ['update:feedbacks'] },
  },
  {
    path: '/pages/faqs',
    name: 'dashboard.pages.faqs',
    component: getComponent('dashboard/pages/FaqsView'),
    meta: { requireAuth: true, authFor: ['read:faqs'] },
  },
  {
    path: '/pages/faqs/create',
    name: 'dashboard.pages.faqs.create',
    component: getComponent('dashboard/pages/FaqFormView'),
    meta: { requireAuth: true, authFor: ['create:faqs'] },
  },
  {
    path: '/pages/faqs/:id/update',
    name: 'dashboard.pages.faqs.update',
    component: getComponent('dashboard/pages/FaqFormView'),
    meta: { requireAuth: true, authFor: ['update:faqs'] },
  },
  {
    path: '/account',
    name: 'dashboard.account',
    component: getComponent('dashboard/AccountView'),
    meta: { requireAuth: true },
  },
  {
    path: '/account/change-password',
    name: 'dashboard.account.changePassword',
    component: getComponent('dashboard/ChangePasswordView'),
    meta: { requireAuth: true },
  },
  {
    path: '/settings',
    name: 'dashboard.settings',
    component: getComponent('dashboard/SettingsView'),
    meta: { requireAuth: true, authFor: ['update:settings'] },
  },
  {
    path: '/payments',
    name: 'dashboard.payments',
    component: getComponent('dashboard/PaymentsView'),
    meta: { requireAuth: true, authFor: ['read:payments'] },
  },
  {
    path: '/logs',
    name: 'dashboard.logs',
    component: getComponent('dashboard/LogsView'),
    meta: { requireAuth: true, authFor: ['read:logs'] },
  },
  {
    path: '/users',
    name: 'dashboard.users',
    component: getComponent('dashboard/UsersView'),
    meta: { requireAuth: true, authFor: ['read:users'] },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
