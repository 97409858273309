<template>
  <div class="tv_alert_wrap" :class="{ active: baseAlert.timeout }">
    <div class="tv_alert_wrap_content">
      <div class="tv_alert_wrap_content_icon">
        <component :is="icons[baseAlert.type].component" v-bind="icons[baseAlert.type].props" scale="1.3" class="mr-3" />
      </div>
      <div class="tv_alert_wrap_content_message">{{ baseAlert.message }}</div>
    </div>
  </div>
</template>

<script>
import { BIconCheckCircle, BIconXCircle, BIconExclamationTriangle } from 'bootstrap-vue'
export default {
  data() {
    return {
      icons: {
        success: { component: BIconCheckCircle, props: { variant: 'success' } },
        danger: { component: BIconXCircle, props: { variant: 'danger' } },
        warning: { component: BIconExclamationTriangle, props: { variant: 'warning' } },
      },
    }
  },

  computed: {
    baseAlert() {
      return this.$store.state.app.baseAlert
    },
  },
}
</script>

<style lang="scss">
.tv_alert_wrap {
  position: fixed;
  display: flex;
  right: -500px;
  top: 104px;
  z-index: 10000;
  transition: 0.2s ease-in-out;

  &_content {
    position: relative;
    display: flex;
    align-items: center;
    padding: 16px;
    box-shadow: 0 0.55rem 0.65rem #00000057;
    min-width: 250px;
    background-color: #fff;
    border-radius: 4px;

    &_message {
      font-weight: bold;
      color: rgb(85, 85, 85);
    }
  }

  &.active {
    right: 20px;
  }
}
</style>
