<template>
  <svg
    :width="width"
    :height="height"
    fill="currentColor"
    stroke="currentColor"
    :stroke-width="strokeWidth"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1_102)">
      <path
        d="M32 15.984C32 7.16 24.832 0 16 0C7.168 0 0 7.16 0 15.984C0 20.844 2.208 25.224 5.664 
        28.164C5.696 28.196 5.728 28.196 5.728 28.228C6.016 28.452 6.304 28.676 6.624 28.9C6.784 
        28.996 6.912 29.122 7.072 29.25C9.71614 31.0427 12.8374 32.0007 16.032 32C19.2266 32.0007 
        22.3479 31.0427 24.992 29.25C25.152 29.154 25.28 29.028 25.44 28.93C25.728 28.708 26.048 
        28.484 26.336 28.26C26.368 28.228 26.4 28.228 26.4 28.196C29.792 25.222 32 20.844 32 15.984V15.984ZM16 
        29.986C12.992 29.986 10.24 29.026 7.968 27.428C8 27.172 8.064 26.918 8.128 26.662C8.31868 25.9682 8.59833 
        25.302 8.96 24.68C9.312 24.072 9.728 23.528 10.24 23.048C10.72 22.568 11.296 22.122 11.872 21.77C12.48 21.418 
        13.12 21.162 13.824 20.97C14.5335 20.7788 15.2652 20.6826 16 20.684C18.1813 20.6686 20.2825 21.5053 21.856 23.016C22.592 
        23.752 23.168 24.616 23.584 25.606C23.808 26.182 23.968 26.79 24.064 27.428C21.7024 29.0883 18.8869 29.9814 16 29.986ZM11.104 
        15.186C10.822 14.5404 10.6803 13.8424 10.688 13.138C10.688 12.436 10.816 11.732 11.104 11.092C11.392 10.452 11.776 9.878 12.256 
        9.398C12.736 8.918 13.312 8.536 13.952 8.248C14.592 7.96 15.296 7.832 16 7.832C16.736 7.832 17.408 7.96 18.048 8.248C18.688 8.536 
        19.264 8.92 19.744 9.398C20.224 9.878 20.608 10.454 20.896 11.092C21.184 11.732 21.312 12.436 21.312 13.138C21.312 13.874 21.184 
        14.546 20.896 15.184C20.6181 15.8145 20.2277 16.3892 19.744 16.88C19.2531 17.363 18.6784 17.7527 18.048 18.03C16.7257 18.5734 
        15.2423 18.5734 13.92 18.03C13.2896 17.7527 12.7149 17.363 12.224 16.88C11.7396 16.3964 11.3586 15.8194 11.104 
        15.184V15.186ZM25.952 25.798C25.952 25.734 25.92 25.702 25.92 25.638C25.6053 24.6368 25.1414 23.6888 24.544 22.826C23.946 
        21.9568 23.2112 21.1902 22.368 20.556C21.724 20.0716 21.026 19.6635 20.288 19.34C20.6238 19.1185 20.9349 18.8617 
        21.216 18.574C21.6931 18.103 22.1121 17.5766 22.464 17.006C23.1727 15.8416 23.5387 14.501 23.52 13.138C23.5299 
        12.129 23.3339 11.1286 22.944 10.198C22.559 9.30128 22.0049 8.48711 21.312 7.8C20.6201 7.12006 19.8058 6.57718 18.912 
        6.2C17.9798 5.81079 16.9781 5.61549 15.968 5.626C14.9577 5.61612 13.956 5.8121 13.024 6.202C12.1225 6.57837 11.3062 7.13277 
        10.624 7.832C9.94408 8.52317 9.40119 9.33684 9.024 10.23C8.6341 11.1606 8.4381 12.161 8.448 13.17C8.448 13.874 8.544 14.546 
        8.736 15.184C8.928 15.856 9.184 16.464 9.536 17.038C9.856 17.614 10.304 18.126 10.784 18.606C11.072 18.894 11.392 19.148 11.744 
        19.372C11.0037 19.7041 10.3054 20.1231 9.664 20.62C8.832 21.26 8.096 22.026 7.488 22.858C6.88452 23.7173 6.42016 24.6662 6.112 
        25.67C6.08 25.734 6.08 25.798 6.08 25.83C3.552 23.272 1.984 19.82 1.984 15.984C1.984 8.28 8.288 1.982 16 1.982C23.712 1.982 
        30.016 8.28 30.016 15.984C30.0118 19.6639 28.5506 23.1925 25.952 25.798V25.798Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: { type: [Number, String], default: 32 },
    height: { type: [Number, String], default: 32 },
    strokeWidth: { type: [Number, String], default: 0 },
  },
}
</script>
