import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from './plugins/axios'

Vue.prototype.$axios = axios

import { BootstrapVue } from 'bootstrap-vue'

Vue.use(BootstrapVue)

import '@/assets/styles/main.scss'

import TimeAgo from 'javascript-time-ago'

import en from 'javascript-time-ago/locale/en'

TimeAgo.addDefaultLocale(en)

Vue.prototype.$timeAgo = new TimeAgo('en-US')

import Cookies from 'js-cookie'
;(async () => {
  if (Cookies.get('token')) {
    let payload = { token: Cookies.get('token'), setCookie: false, pushToDashboard: false }

    store.commit('SET_TOKEN', payload)

    await store.dispatch('getMe')
  }
})()

router.beforeEach(async (to, from, next) => {
  let isLoggedIn = store.state.auth.isLoggedIn

  if (isLoggedIn) {
    if (to.meta.preventAuth && to.name != 'dashboard') {
      return next({ name: 'dashboard' })
    } else {
      let routePermissions = to.meta.authFor || []

      let me = store.state.auth.me

      if (!me._id) {
        await store.dispatch('getMe')
        me = store.state.auth.me
      }

      if (routePermissions) {
        if (!routePermissions.every((p) => me.permissions.includes(p)) && !me.isOwner) {
          return next({ name: 'dashboard' })
        }
      }
      return next()
    }
  }

  if (to.meta.requireAuth) {
    return next({ name: 'login', params: { next: { name: to.name } } })
  }

  return next()
})

import globalMixin from '@/mixins/globalMixin'

Vue.mixin(globalMixin)

import '@/filters'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
