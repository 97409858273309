<template>
  <svg
    :width="width"
    :height="height"
    fill="currentColor"
    stroke="currentColor"
    :stroke-width="strokeWidth"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1_2)">
      <path
        d="M16 8.42123C13.5318 8.42123 11.5238 10.4293 11.5238 12.8974C11.5238 15.3656 13.5319 17.3735 16 17.3735C18.4682 17.3735 20.4761 15.3655 
        20.4761 12.8974C20.4762 10.4292 18.4682 8.42123 16 8.42123ZM16 16.3993C14.0691 16.3993 12.4982 14.8283 12.4982 12.8974C12.4982 10.9665 
        14.0691 9.39561 16 9.39561C17.931 9.39561 19.5019 10.9665 19.5019 12.8974C19.5019 14.8283 17.9309 16.3993 16 16.3993Z"
        fill="currentColor"
      />
      <path
        d="M16 17.729C13.2874 17.729 11.0805 19.9358 11.0805 22.6483V30.2271H20.9193V22.6483C20.9193 19.9358 18.7125 17.729 16 17.729ZM12.0548 
        29.2528V22.6483C12.0548 20.473 13.8246 18.7033 15.9999 18.7033C18.1752 18.7033 19.9449 20.473 19.9449 22.6483V29.2528H12.0548Z"
        fill="currentColor"
      />
      <path
        d="M27.0806 11.0806C24.368 11.0806 22.1613 13.2875 22.1613 16V23.5787H32.0001V16C31.9999 13.2874 29.7931 11.0806 27.0806 11.0806ZM23.1355 
        22.6045V16C23.1355 13.8247 24.9052 12.0549 27.0805 12.0549C29.2558 12.0549 31.0255 13.8247 31.0255 16V22.6045H23.1355Z"
        fill="currentColor"
      />
      <path
        d="M4.91937 11.0806C2.20687 11.0806 0 13.2874 0 16V23.5787H9.83879V16C9.83879 13.2874 7.63199 11.0806 4.91937 11.0806ZM8.86448 
        22.6045H0.974311V16C0.974311 13.8247 2.74406 12.0549 4.91937 12.0549C7.09474 12.0549 8.86442 13.8247 8.86442 16V22.6045H8.86448Z"
        fill="currentColor"
      />
      <path d="M16.4753 19.9375H15.5659V20.9118H16.4753V19.9375Z" fill="currentColor" />
      <path d="M16.4753 21.6912H15.5659V22.6655H16.4753V21.6912Z" fill="currentColor" />
      <path d="M16.4753 23.5099H15.5659V24.4843H16.4753V23.5099Z" fill="currentColor" />
      <path d="M27.5177 13.312H26.6733V14.2863H27.5177V13.312Z" fill="currentColor" />
      <path d="M27.5177 15.0658H26.6733V16.0401H27.5177V15.0658Z" fill="currentColor" />
      <path d="M27.5177 16.8196H26.6733V17.7939H27.5177V16.8196Z" fill="currentColor" />
      <path d="M5.36793 13.312H4.5235V14.2863H5.36793V13.312Z" fill="currentColor" />
      <path d="M5.36793 15.0658H4.5235V16.0401H5.36793V15.0658Z" fill="currentColor" />
      <path d="M5.36793 16.8196H4.5235V17.7939H5.36793V16.8196Z" fill="currentColor" />
      <path
        d="M16.0204 9.75092V10.7252C17.1896 10.7252 18.1721 11.6883 18.1721 12.9225H19.1464C19.1465 11.1687 17.7092 9.75092 16.0204 9.75092Z"
        fill="currentColor"
      />
      <path
        d="M18.0487 13.6217C17.9278 13.9637 17.7201 14.2731 17.448 14.5166L18.0978 
        15.2426C18.4915 14.8903 18.7922 14.442 18.9673 13.9463L18.0487 13.6217Z"
        fill="currentColor"
      />
      <path
        d="M4.91935 1.77295C2.45117 1.77295 0.443237 3.78101 0.443237 6.24907C0.443237 
        8.71725 2.45123 10.7252 4.91935 10.7252C7.38754 10.7252 9.39553 8.71719 9.39553 
        6.24907C9.3956 3.78095 7.38754 1.77295 4.91935 1.77295ZM4.91935 9.75093C2.98842 
        9.75093 1.41755 8.18 1.41755 6.24907C1.41755 4.31819 2.98842 2.74726 4.91935 
        2.74726C6.85029 2.74726 8.42122 4.31819 8.42122 6.24907C8.42129 8.18 6.85029 9.75093 4.91935 9.75093Z"
        fill="currentColor"
      />
      <path
        d="M4.91306 3.10257V4.07688C6.14718 4.07688 7.09161 5.06288 7.09161 6.23213H8.06592C8.06592 4.54325 6.6668 3.10257 4.91306 3.10257Z"
        fill="currentColor"
      />
      <path
        d="M6.96806 6.9733C6.84724 7.31523 6.63949 7.62467 6.36743 7.86823L7.01724 
        8.59423C7.41087 8.24192 7.71149 7.79367 7.88674 7.29798L6.96806 6.9733Z"
        fill="currentColor"
      />
      <path
        d="M27.0806 1.77295C24.6124 1.77295 22.6045 3.78101 22.6045 6.24907C22.6045 8.71725 24.6124 10.7252 27.0806 10.7252C29.5488 
        10.7252 31.5568 8.71719 31.5568 6.24907C31.5568 3.78095 29.5487 1.77295 27.0806 1.77295ZM27.0806 9.75093C25.1496 9.75093 
        23.5788 8.18 23.5788 6.24907C23.5788 4.31819 25.1496 2.74726 27.0806 2.74726C29.0115 2.74726 30.5824 4.31819 30.5824 
        6.24907C30.5824 8.18 29.0115 9.75093 27.0806 9.75093Z"
        fill="currentColor"
      />
      <path
        d="M27.0628 3.10257V4.07688C28.2969 4.07688 29.2528 5.06288 29.2528 6.23213H30.2271C30.2271 4.54325 28.8166 3.10257 27.0628 3.10257Z"
        fill="currentColor"
      />
      <path
        d="M29.1292 6.97324C29.0083 7.31524 28.8006 7.62467 28.5286 7.86823L29.1784 
        8.59417C29.5721 8.24186 29.8727 7.79367 30.0479 7.29799L29.1292 6.97324Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: { type: [Number, String], default: 32 },
    height: { type: [Number, String], default: 32 },
    strokeWidth: { type: [Number, String], default: 0 },
  },
}
</script>
